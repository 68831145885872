import React from 'react'

const CardsFunciona = ({ text, title, descripcion, text1 }) => {
    return (
        <div className='grid gap-[24px] max-w-[340px] mx-auto w-full h-full rounded-[4px]
            p-[20px] md:p-[16px] lg:p-[20px] select-none'
            style={{ boxShadow: '-0.67px 4.04px 9.42px rgba(0, 0, 0, 0.15)', }}>
            <div className='grid gap-4 lg:gap-6'>
                <div className='grid gap-[14px] mx-auto max-w-[289px] w-full'>
                    <img
                        className='w-[28px] md:w-[24px] lg:w-[28px]
                        mx-auto'
                        src={text}
                        alt={title} />
                    <h3
                        className='font-[Jost] text-center text-greenV
                        text-[20px] leading-[130%] font-semibold
                        lg:text-[26px]'>
                        {title}
                    </h3>
                </div>
                <p
                    className='font-Nunito text-center
                    text-[14px] leading-[130%] font-normal
                    md:text-[16px] md:leading-[20px]
                    lg:leading-[150%]'>
                    {descripcion}
                </p>
            </div>
            <img className='h-auto w-full object-cover
                rounded-[4px] md:rounded-[3px] lg:rounded-[2px]'
                src={text1}
                alt={title} />
        </div>
    )
}

export default CardsFunciona
