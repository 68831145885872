import React, { useState } from 'react'

const BtnCotiza = ({ color, colorHover }) => {
    const [isHovered, setIsHovered] = useState(false);
    const buttonStyle = {
        backgroundColor: isHovered ? colorHover : color,
    };
    return (
        <a 
            href='https://murales.vertices.pro/'
            target='_blank'
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <button
                className="rounded-lg transition-all ease-linear
                font-[Jost] font-bold text-white
                w-[208px] h-[45px] text-[18px]
                sm:w-[192px] sm:h-[50px]
                lg:w-[220px] lg:text-[20px]"
                style={buttonStyle}>
                Cotiza tu mural
            </button>
        </a>
    )
}

export default BtnCotiza
