import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import CardComparte from '../cards/CardComparte';
import { Autoplay } from 'swiper/modules';

export default function SwiperCcomparte() {

    return (
        <>
            <Swiper
                style={{
                    "--swiper-pagination-color": "rgba(43, 43, 43, 1)",
                    "--swiper-pagination-bullet-inactive-color": "rgba(43, 43, 43, 0.5)",
                    "--swiper-pagination-bullet-size": "15px",
                    "--swiper-pagination-bullet-horizontal-gap": "8px",
                }}
                loop={true}
                centeredSlides={true}
                breakpoints={{
                    270: {
                        slidesPerView: 1,
                    },
                    460: {
                        slidesPerView: 1.2,
                    },
                    640: {
                        slidesPerView: 1.8,
                    },
                    830: {
                        slidesPerView: 2.4,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <CardComparte img="1" />
                </SwiperSlide>
                <SwiperSlide>
                    <CardComparte img="2" />
                </SwiperSlide>
                <SwiperSlide>
                    <CardComparte img="3" />
                </SwiperSlide>
                <SwiperSlide>
                    <CardComparte img="4" />
                </SwiperSlide>
                <SwiperSlide>
                    <CardComparte img="5" />
                </SwiperSlide>
                <SwiperSlide>
                    <CardComparte img="6" />
                </SwiperSlide>
            </Swiper>
        </>
    );
}
