import React from 'react'
import NavBar from '../home/shared/NavBar'
import Footer from '../home/shared/Footer'
import Home from '../home/shared/Home'
import { motion } from 'framer-motion'
import BtnWhatsApp from '../components/buttons/BtnWhatsApp'

const Main = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }} 
            className='relative overflow-x-hidden'>
            <NavBar />
            <Home />
            <Footer />
            <BtnWhatsApp msj="¡Hola, cotiza tu mural con nosotros! 🖼️🎨🖌️" />
        </motion.div>
    )
}

export default Main
