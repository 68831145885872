import React from 'react'
import H2 from '../components/titles/H2'
import { AccordionFAQ } from '../components/swipers/AccordionFAQ'
import CardsFunciona from '../components/cards/CardsFunciona'
import BtnCotiza from '../components/buttons/BtnCotiza'
import BtnCotizaTransparent from '../components/buttons/BtnCotizaTransparent'
import SwiperHero from '../components/swipers/SwiperHero'
import SwiperMurales from '../components/swipers/SwiperMurales'
import { motion } from "framer-motion"
import SwiperCcomparte from '../components/swipers/SwiperCcomparte'
import BtnConversemos from '../components/buttons/BtnConversemos'

const Home = () => {
    return (
        <motion.div
            initial={{ opacity: 0, x: 0 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 0 }}
            transition={{ duration: 0.5 }}
            className='relative z-10 mt-[59px] phone:mt-[72px] sm:mt-[88px] lg:mt-[104px]'>
            <section id='Hero'>
                <div className='containerW1200 grid items-center
                    pb-[32px] pt-[64px] gap-[56px]
                    md:pb-[20px] md:pt-[62px] md:gap-[42px]
                    md:grid-cols-[1fr_280px] mdAux:grid-cols-[1fr_326px]
                    lg:pb-[45px] lg:gap-[50px] lg:grid-cols-[1fr_500px]
                    desktop:grid-cols-[1fr_623px]'>
                    <div className='grid items-center h-min
                        gap-[40px] justify-items-center 
                        md:gap-[26px] md:justify-items-start
                        lg:gap-[40px] '>
                        <h1 className='font-[Jost] font-semibold  text-blackV
                            text-[32px] leading-[130%] text-center
                            mdAux:text-[38px] mdAux:leading-[42px] md:text-left
                            xl:text-[55px] lg:leading-[130%]'>
                            Murales artísticos <br /> eco amigables
                        </h1>
                        <p className='font-Nunito font-normal  w-[80%] max-w-[404px]
                            text-[16px] leading-[160%] text-center sm:w-auto mt-[-18px]
                            md:text-[18px] md:leading-[26px] md:text-left'>
                            Cotiza, diseña y ejecuta murales artísticos únicos y personalizados para tu negocio o espacio personal con impacto ambiental consciente.
                        </p>
                        <div className='grid gap-[12px]'>
                            <BtnCotizaTransparent />
                            <BtnConversemos />
                        </div>
                    </div>
                    <div
                        className='max-w-[254px] mx-auto
                        phone:max-w-[326px]
                        sm:max-w-[326px]
                        md:max-w-[280px]
                        mdAux:max-w-[326px]
                        lg:max-w-[500px]
                        desktop:max-w-[623px]'>
                        <SwiperHero />
                    </div>
                </div>
            </section>
            <section id='Comparte'
                className="bg-[url('/public/home/bg/bg-comparte.png')] bg-cover 
                py-[48px] sm:pt-[50px] sm:pb-[60px]">
                <div className='grid gap-[45px]'>
                    <div className='grid w-[90%] lg:!max-w-[790px] justify-center mx-auto
                        gap-[40px] md:gap-[34px] lg:gap-[48px] lg:w-full'>
                        <H2 text='Comparte nuestro propósito' colorTilte="!text-white" />
                        <p className='font-Nunito text-center mx-auto text-white text-[14px]
                            mdAux:text-[18px] mdAux:leading-[26px]'>
                            <span className='font-bold text-[16px] mdAux:text-[20px]'>
                                ¿Por qué deberías trabajar con nosotros?
                            </span>
                            <br /> <br />
                            Un porcentaje de nuestros ingresos son destinados a brindar talleres de arte gratuitos para niños y adolescentes en zonas vulnerables a través de nuestra iniciativa social
                            <span> {" "}
                                <a
                                    target='_blank'
                                    href='https://coloriza.pe/galeria-urbana/'
                                    className='text-pinkV underline'>
                                    COLORIZA
                                </a> 
                            </span> {" "}
                            y otros proyectos sociales y culturales. 
                            <br /> <br />
                            Además apoyamos organizando eventos y muralizaciones colectivas sin fines de lucro en diferentes ciudades del Perú y Latinoamérica.
                        </p>
                        <img
                            src='/svg/logo-coloriza.svg'
                            alt='Coloriza'
                            className='mx-auto
                            w-[80%] sm:w-[406px]'
                        />
                    </div>
                    <div className='containerSwipers mx-auto'>
                        <SwiperCcomparte />
                    </div>
                </div>
            </section>
            <section id='Funciona'>
                <div className='containerW1200
                    py-[64px] md:py-[72px] lg:py-[88px]'>
                    <div>
                        <H2 text='¿Cómo funciona el cotizador?' />
                        <div className='grid gap-[48px] max-w-[1165px] py-[56px] 
                                sm:grid-cols-2
                                md:grid-cols-3 md:gap-[24px]
                                mdAux:gap-[36px]
                                lg:gap-[56px] lg:py-[72px]
                                lgAux:gap-[82px] '>
                            <CardsFunciona
                                text='/home/svg/estilo.svg'
                                title='Elige tu estilo'
                                descripcion='Explora y selecciona estilos modernos y clásicos para personalizar tu mural'
                                text1='/home/funciona/estilo.png' />
                            <CardsFunciona
                                text='/home/svg/espacio.svg'
                                title='Define el espacio'
                                descripcion='Escoge superficies y medidas ajustando cada característica de tu mural'
                                text1='/home/funciona/espacio.png' />
                            <CardsFunciona
                                text='/home/svg/detalles.svg'
                                title='Completa los detalles'
                                descripcion='Especifica fecha, ubicación y datos, al final recibirás un correo de confirmación'
                                text1='/home/funciona/detalles.png' />
                        </div>
                        <p className='font-Nunito font-semibold text-center mx-auto
                            text-[20px] leading-8
                            md:tex-[26px]'>
                            ¡Nuestro equipo te ayudará a transformar tus paredes en obras de arte!
                        </p>
                    </div>
                </div>
            </section>
            <section id='Espacio'
                className="bg-[url('/public/home/bg/bg-video.png')] bg-cover">
                <div className='w-full max-w-[1111px] mx-auto px-[5%] xl:px-0
                    grid py-[64px] gap-[40px]
                    md:grid-cols-[1.6fr,_1fr] md:py-[72px] md:gap-[36px]
                    lg:py-[88px] lgAux:gap-[104px]'>
                    <div className='w-full'>
                        <iframe
                            src="https://www.youtube.com/embed/e_AiO9STtDY?si=rp7kILrIiQntefmt"
                            title="Murales VERTICES 2024"
                            className='object-cover w-full h-[260px] sm:h-[252px] lg:h-[360px]'>
                        </iframe>
                    </div>
                    <div className='grid place-content-center justify-items-center
                        gap-[40px]
                        md:gap-[24px] md:justify-items-start
                        lg:gap-[40px]'>
                        <h2
                            className=' text-white normal-case  font-[Jost] !font-semibold
                            !text-[28px] leading-[32px] text-center
                            sm:text-[32px] sm:leading-[40px] md:text-left
                            lg:!text-[40px] lg:leading-[55px]'>
                            ¡Haz de tu espacio algo extraordinario!
                        </h2>
                        <BtnCotiza color="#2ABC05" colorHover="#66C14F" />
                    </div>
                </div>
            </section>
            <section id='Murales-realizados'>
                <div className='grid
                    py-[64px] gap-[48px]
                    md:pt-[36px] md:pb-[72px] md:gap-[64px] 
                    lg:pt-[44px] lg:pb-[88px]'>
                    <H2 text="¡Murales realizados!" />
                    <div className='containerSwipers'>
                        <SwiperMurales />
                    </div>
                </div>
            </section>
            <section id='Aliados'>
                <div className='containerW1200 grid 
                    py-[32px] gap-[48px]  
                    md:py-[36px] md:gap-[56px] 
                    lg:py-[44px] lg:gap-[72px]'>
                    <div className='grid max-w-[765px] justify-center mx-auto
                        gap-[40px] md:gap-[34px] lg:gap-[48px] '>
                        <H2 text="Aliados estratégicos" />
                        <p className='font-[Jost] text-center mx-auto  max-w-[684px] 
                            text-[16px] leading-6 md:px-[25px]
                            mdAux:tex-[18px] mdAux:leading-[26px] mdAux:px-[40px]'>
                            Colaboramos con artistas, entidades y organizaciones culturales de toda latinoamérica, organizando eventos y compartiendo experiencias
                        </p>
                    </div>
                    <div
                        className='items-center justify-center justify-items-center
                        grid grid-cols-3 gap-x-[24px] gap-y-[16px]
                        sm:grid-cols-4
                        md:grid-cols-5 md:gap-x-[42px] md:gap-y-[32px]
                        lg:grid-cols-6 lg:gap-x-[80px] lg:gap-y-[40px] '>
                        <img src="/home/svg/aliados/logo1.svg" alt="Ciclos" />
                        <img src="/home/svg/aliados/logo2.svg" alt="step-up" />
                        <img src="/home/svg/aliados/logo3.svg" alt="road-paint" />
                        <img src="/home/svg/aliados/logo4.svg" alt="spray-satino" />
                        <img src="/home/svg/aliados/logo5.svg" alt="one-two" />
                        <img src="/home/svg/aliados/logo6.svg" alt="joven" />
                        <img src="/home/svg/aliados/logo7.svg" alt="Dors" />
                        <img src="/home/svg/aliados/logo8.png" alt="montana-colors" />
                        <img src="/home/svg/aliados/logo9.svg" alt="meeeting-of" />
                        <img src="/home/svg/aliados/logo10.svg" alt="green-grafe" />
                        <img src="/home/svg/aliados/logo11.svg" alt="barranco" />
                        <img src="/home/svg/aliados/logo12.png" alt="rumba-7" />
                        <img src="/home/svg/aliados/logo13.svg" alt="indeleble" />
                        <img src="/home/svg/aliados/logo14.svg" alt="shark" />
                        <img src="/home/svg/aliados/logo15.svg" alt="viacodigo" />
                        <img src="/home/svg/aliados/logo16.svg" alt="van" className='md:grid md:col-start-2 lg:col-start-4' />
                        <img src="/home/svg/aliados/logo17.png" alt="recidar" />
                        <img src="/home/svg/aliados/logo18.svg" alt="san-miguel" />
                    </div>
                </div>
            </section >
            <section id='Preguntas-frecuentes'>
                <div className='containerW1200 grid 
                    py-[64px] gap-[48px]  
                    md:pt-[36px] md:pb-[72px] md:gap-[56px] 
                    lg:pt-[44px] lg:pb-[88px] lg:gap-[72px]'>
                    <H2 text="Preguntas frecuentes" />
                    <div className='mx-auto max-w-[792px]'>
                        <AccordionFAQ />
                    </div>
                </div>
            </section>
        </motion.div>
    )
}

export default Home
