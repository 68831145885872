import React from 'react'

const Fb = () => {
    return (
        <a
            href='https://www.facebook.com/vertices.pe'
            target='_blank'
            className='cursor-pointer'>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="49"
                height="48"
                fill="none"
                viewBox="0 0 49 48"
            >
                <circle cx="24.5" cy="24" r="24" fill="#fff"></circle>
                <path
                    fill="#2B2B2B"
                    d="M38.5 24.035C38.5 16.288 32.228 10 24.5 10s-14 6.288-14 14.035c0 6.793 4.816 12.45 11.2 13.754v-9.543h-2.8v-4.21h2.8v-3.51c0-2.709 2.198-4.912 4.9-4.912h3.5v4.21h-2.8c-.77 0-1.4.632-1.4 1.404v2.807h4.2v4.21h-4.2V38c7.07-.702 12.6-6.68 12.6-13.965z"
                ></path>
            </svg>
        </a>
    )
}

export default Fb
