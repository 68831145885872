import React from 'react'

const H2 = ({ text, colorTilte }) => {
    return (
        <div className='grid gap-[24px] lg:gap-[32px]'>
            <h2
                className={`font-[Jost] font-semibold text-blackV text-center 
                text-[28px] leading-[32px] !normal-case
                sm:text-[32px] sm:leading-[40px] 
                lg:text-[40px] lg:leading-[55px] ${colorTilte}`}>
                {text}
            </h2>
            <div
                className='bg-pinkV mx-auto
                w-[94px] h-[3px]
                sm:w-[104px] sm:h-[4px]
                lg:h-[5px]'
            />
        </div>
    )
}

export default H2
