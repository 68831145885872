import React from 'react'
import copy from 'clipboard-copy'

const Contac = ({ text, img, alt }) => {

    const textToCopy = text;
    const handleCopyClick = async () => {
        try {
            await copy(textToCopy);
            alert('copiado!!!')
        } catch (error) {
            console.error("Error", error);
        }
    };

    return (
        <div
            className='flex gap-6 justify-center items-center cursor-pointer !z-30
            md:gap-[10px] '
            onClick={handleCopyClick}
        >
            <img
                className='h-[28px] w-auto cursor-pointer'
                src={img}
                alt={alt}
            />
            <p
                className=' text-center font-semibold font-[Jost] cursor-pointer
                text-[16px] phone:text-[20px] lg:text-[26px]'
            >
                {text}
            </p>
        </div>
    )
}

export default Contac
