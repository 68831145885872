import React from 'react'
import { FloatingWhatsApp } from "react-floating-whatsapp";

const BtnWhatsApp = ({msj}) => {
    return (
        <div
            className="fixed bottom-[3%] right-[3%] !z-30">
            <FloatingWhatsApp phoneNumber="+51961430490" accountName="Vértices" statusMessage=""
                chatMessage={msj}
                avatar="vertices-logo.jpg"
                placeholder="Escribe aquí..." />
        </div>
    )
}

export default BtnWhatsApp

//"Hola, somos Vértices. ¿Quieres cotizar un mural, participar como artista o sumarte como aliado?" 
//"¡Hola, cotiza tu mural con nosotros! 🖼️🎨🖌️"