import React from 'react'

const BtnConversemos = () => {
    return (
        <a
            href='https://api.whatsapp.com/send?phone=+51961430490&text=Hola,%20somos%20V%C3%A9rtices.%20%C2%BFCu%C3%A9ntanos%20c%C3%B3mo%20podemos%20ayudarte?'
            target='_blank'>
            <button
                className='rounded-lg border-2 border-blackV 
                font-[Jost] font-bold text-blackV bg-white
                w-[208px] h-[45px] text-[18px]
                sm:w-[224px]
                lg:w-[268px] lg:text-[22px]
                transition-all ease-linear
                hover:bg-blackV hover:bg-opacity-10'>
                ¡Conversemos!
            </button>
        </a>
    )
}

export default BtnConversemos
