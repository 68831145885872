import React from 'react'

const Ig = () => {
    return (
        <a
            href='https://www.instagram.com/vertices.pro/'
            target='_blank'
            className='cursor-pointer'>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="49"
                height="48"
                fill="none"
                viewBox="0 0 49 48"
            >
                <circle cx="24.5" cy="24" r="24" fill="#fff"></circle>
                <path
                    fill="#2B2B2B"
                    d="M18.62 10h11.76c4.48 0 8.12 3.64 8.12 8.12v11.76A8.12 8.12 0 0130.38 38H18.62c-4.48 0-8.12-3.64-8.12-8.12V18.12A8.12 8.12 0 0118.62 10zm-.28 2.8a5.04 5.04 0 00-5.04 5.04v12.32a5.037 5.037 0 005.04 5.04h12.32a5.04 5.04 0 005.04-5.04V17.84a5.037 5.037 0 00-5.04-5.04H18.34zm13.51 2.1a1.75 1.75 0 110 3.5 1.75 1.75 0 010-3.5zM24.5 17a7 7 0 110 14 7 7 0 010-14zm0 2.8a4.2 4.2 0 100 8.4 4.2 4.2 0 000-8.4z"
                ></path>
            </svg>
        </a>
    )
}

export default Ig
