import React from 'react'

const CardComparte = ({img}) => {
    return (
        <img
            src={`/home/comparte/img${img}.png`}
            alt='Foto coloriza'
            className='w-[240px] phone:w-[300px] mx-auto'>
        </img>
    )
}

export default CardComparte
