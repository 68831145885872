import React from 'react'
import { useMediaQuery } from 'react-responsive'

const BtnCotizaNavbar = () => {
    const isSm = useMediaQuery({ query: '(min-width: 300px)' })
    return (
        <a
            href='https://murales.vertices.pro/'
            target='_blank'>
            <button
                className='bg-pinkV rounded-lg text-white
                font-[Jost] font-bold 
                w-[108px] h-[30px] text-[12px]
                phone:w-[144px] phone:h-[45px] phone:text-[18px]
                sm:w-[192px] sm:h-[50px] 
                lg:w-[220px] lg:text-[22px]
                transition-all ease-linear hover:bg-[#F0619A]'>
                Ir al cotizador
            </button>
        </a>
    )
}

export default BtnCotizaNavbar
