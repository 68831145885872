import React from 'react'

const BtnCotizaTransparent = () => {
    return (
        <a
            href='https://murales.vertices.pro/'
            target='_blank'>
            <button
                className='rounded-lg border-2 border-blackV 
                font-[Jost] font-bold text-white bg-blackV
                w-[208px] h-[45px] text-[18px]
                sm:w-[224px]
                lg:w-[268px] lg:text-[22px]
                transition-all ease-linear
                hover:bg-opacity-90 '>
                Cotiza tu mural
            </button>
        </a>
    )
}

export default BtnCotizaTransparent
