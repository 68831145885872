import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';

export default function SwiperHero() {
    return (
        <>
            <Swiper
                style={{
                    "--swiper-pagination-color": "rgba(255, 255, 255, 0.8)",
                    "--swiper-pagination-bullet-inactive-color": "rgba(0, 0, 0, 0.9)",
                    "--swiper-pagination-bullet-size": "20px",
                    "--swiper-pagination-bullet-horizontal-gap": "4px",
                }}
                slidesPerView={1}
                spaceBetween={10}
                loop={true}
                pagination={{
                    dynamicBullets: true,
                }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination]}
                className="mySwiper">
                <SwiperSlide>
                    <img
                        src='/home/hero/hero1.png'
                        alt='Hero 1'
                        className='w-full' />
                </SwiperSlide>
                <SwiperSlide>
                    <img
                        src='/home/hero/hero2.png'
                        alt='Hero 2'
                        className='w-full' />
                </SwiperSlide>
                <SwiperSlide>
                    <img
                        src='/home/hero/hero3.png'
                        alt='Hero 3'
                        className='w-full' />
                </SwiperSlide>
            </Swiper>
        </>
    );
}
