import React from 'react';
import Ig from '../components/svg/Ig';
import Fb from '../components/svg/Fb';
import BtnCotiza from '../components/buttons/BtnCotiza';
import Contac from '../components/cards/Contac';
import { useMediaQuery } from 'react-responsive';


const Footer = () => {
   const isSm = useMediaQuery({ query: '(min-width: 640px)' })
   return (
      <footer
         className={`bg-cover text-white relative 
         ${isSm ? 'bg-[url("/public/home/bg/bg-footer.png")]' : 'bg-[url("/public/home/bg/bg-footermobile.png")]'}
          bg-right sm:bg-left`}>
         <div
            className='w-full h-full absolute bg-[rgba(0,0,0,0.7)] !z-20'
         />
         <div className='max-w-[1200px] mx-auto !z-30 w-[92%]
            py-[64px] sm:py-[72px] lg:py-[88px]'>
            <div
               className='grid gap-[56px] justify-center items-center !z-30
               mdAux:flex mdAux:justify-between'>
               <h1
                  className='font-[Jost] font-semibold !z-30
                  text-[28px] leading-[40px] text-center max-w-[415px]
                  md:text-[34px] md:leading-[48px] mdAux:text-left mdAux:max-w-[512px]
                  lg:text-[40px] lg:leading-[56px]'>
                  ¡Dale un toque único a tu espacio junto a VÉRTICES!
               </h1>
               <div className='flex justify-center !z-30'>
                  <BtnCotiza color="#EA2675" colorHover="#F0619A" />
               </div>
            </div>
            <hr className='border-t-2 border-white relative !z-30
               my-[64px] md:my-[56px]' />
            <div
               className='grid items-center justify-center gap-[56px] !z-30
               mdAux:grid-flow-col mdAux:justify-between '>
               <div className='grid gap-[32px] !z-30
                  md:grid-flow-col md:gap-[56px]
                  lg:gap-[72px]'>
                  <Contac
                     text='+51 991 231 559'
                     img="/home/svg/contactos/phone.svg"
                     alt='phone'
                  />
                  <Contac
                     text="vertices.pro@gmail.com"
                     img="/home/svg/contactos/email.svg"
                     alt='emal'
                  />
               </div>
               <div className='flex gap-[16px] mx-auto !z-30'>
                  <Ig />
                  <Fb />
               </div>
            </div>
            <p
               className='font-[500] font-[Jost] text-center leading-6 text-[14px] min-w-[273px]
               pt-[72px] px-[14px] cursor-default !z-30 relative
               mdAux:text-left 
               lg:px-0 lg:text-[16px]'>
               © Copyright 2024{" "}
               <span className='font-bold'>VÉRTICES</span>
               {" "}| All Rights Reserved | Powered by{" "}
               <a
                  href="https://ciclos.studio/"
                  target='_blank'
                  className='font-bold transition-all ease-linear hover:text-pinkV' >
                  CICLOS STUDIO
               </a>
            </p>
         </div>
      </footer>
   );
};

export default Footer