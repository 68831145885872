import { useEffect, useState } from "react";
import Splash from "./components/animations/Splash";
import { AnimatePresence } from "framer-motion";
import { Route, Routes } from "react-router-dom";
import B2B from "./routes/B2B";
import ScrollToTop from "./hooks/ScrollToTop";
import axios from "axios";
import Murales from "./routes/Murales";
import Main from "./routes/Main";

function App() {
  const [splash, setSplash] = useState({ state: true, dataHome: null, dataB2B: null })


  useEffect(() => {

    const fetchFunction = async () => {
      var dataHome = null
      try {
        await axios.get("https://www.api.vertices.pro/wp-json/wp/v2/pages/5")
          .then((res) => {
            dataHome = res.data.acf
          })

        await axios.get("https://www.api.vertices.pro/wp-json/wp/v2/pages/7")
          .then((res) => {
            setSplash({ dataHome: dataHome, state: false, dataB2B: res.data.acf })
          })
      } catch (error) {
        alert("Error de conexión con el servidor")
      }
    }

    fetchFunction()

  }, [])

  return (
    <AnimatePresence>
      {
        splash.state ?
          <Splash />
          :
          <>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Main data={splash.dataHome} />} />
              {/**
              *  <Route path="/organizaciones" element={<B2B data={splash.dataB2B} />} />
              <Route path="/murales" element={<Murales data={splash.dataHome} />} />
              <Route path="/cotizador-de-murales" element={<Main data={splash.dataHome} />} />
              */}
            </Routes>
          </>
      }
    </AnimatePresence>
  );
}

export default App;
