import React, { useState } from "react";
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";

export function AccordionFAQ() {
    const [open, setOpen] = useState(1);

    const handleOpen = (value) => setOpen(open === value ? 0 : value);

    const CUSTOM_ANIMATION = {
        mount: { scale: 1 },
        unmount: { scale: 0.9 },
    };

    function Icon({ id }) {
        return (
            <div
                className={`${id === open && "rotate-180"} 
                flex justify-self-end ml-[12px] w-[16px]
                transition-all ease-in-out duration-200`}>
                <svg
                    width="16"
                    height="11"
                    viewBox="0 0 16 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 2.5L8 10.5L16 2.5L14 0.5L8 6.5L2 0.5L0 2.5Z"
                        fill={`${id === open ? "#EA2675" : "#2B2B2B"} `}
                    />
                </svg>
            </div>
        );
    }

    const FAQData = [
        {
            id: 1,
            question: "¿Qué es Vértices?",
            answer: "Somos Vértices y recuperamos espacios públicos a través del graffiti y el arte urbano en diversas zonas de Lima, fomentando valores cívicos en la ciudadanía."
        },
        {
            id: 2,
            question: "¿Cuál es el propósito de Vértices?",
            answer: "Con Vértices, conectamos tu visión con muralistas talentosos, impulsando proyectos creativos y respaldando el crecimiento artístico."
        },
        {
            id: 3,
            question: "¿Cuál es la modalidad de pago?",
            answer: "Simplificamos el pago con depósito en cuenta. Te enviaremos instrucciones para una transacción fácil, segura y cómoda."
        },
        {
            id: 4,
            question: "¿Se pueden ajustar detalles del mural después de cotizar?",
            answer: "¡Claro! Podemos coordinar cambios en los detalles del mural antes de comenzar su creación."
        },
        {
            id: 5,
            question: "¿En cuánto tiempo tendré mi mural listo?",
            answer: "Cada mural tiene sus propias dimensiones y características, si se trata de un mural aproximado de 5 metros de ancho por 3 metros de alto se pueden asignar 3-4 días al proyecto. La estimación puede variar según las particularidades de cada proyecto."
        }
    ];

    return (
        <div className="grid gap-[24px] !hyphens-none">
            {FAQData.map((item) => (
                <Accordion
                    key={item.id}
                    open={open === item.id}
                    animate={CUSTOM_ANIMATION}
                    icon={<Icon id={item.id} />}
                    className="grid gap-0 p-[16px] rounded-[4px] sm:py-[24px] sm:px-[40px]"
                    style={{ boxShadow: '0px 4px 10px 1px rgba(0, 0, 0, 0.15)' }} >
                    <AccordionHeader
                        onClick={() => handleOpen(item.id)}
                        className={`${open === item.id && "!text-pinkV font-bold"} 
                        font-Nunito  text-left transition-all ease-in-out text-blackV
                        text-[16px] font-bold leading-[22px]
                        sm:text-[18px] sm:!font-semibold sm:leading-normal`}
                    >
                        {item.question}
                    </AccordionHeader>
                    <AccordionBody
                        className={`${open !== item.id && "!text-transparent select-none transition-all duration-200"}
                        pt-[14px] cursor-default font-Nunito 
                        text-[14px] leading-[20px] 
                        sm:text-[16px] sm:leading-[26px] 
                        md:leading-[24px]`}>
                        {item.answer}
                    </AccordionBody>
                </Accordion>
            ))}
        </div>
    );
}
