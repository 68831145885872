import React, { useState } from 'react'
import { motion, AnimatePresence } from "framer-motion"
import { useMediaQuery } from 'react-responsive'

const CardMurales = ({ img, title, description, type }) => {
	const [info, setInfo] = useState(false)
	const isMd = useMediaQuery({ query: '(min-width: 768px)' })
	return (
		<div
			onMouseEnter={() => setInfo(true)}
			onMouseLeave={() => setInfo(false)}
			onClick={() => setInfo(!info)}
			className='cursor-pointer bg-cover bg-no-repeat bg-center relative
         	w-[92%] h-[280px] mb-[62px] phone:h-[326px] !select-none mx-auto
         	sm:w-full sm:h-[380px] sm:mb-[80px] transition-all
         	md:w-[610px] md:h-[380px] 
         	lg:w-[700px] lg:h-[440px]'
			style={{ backgroundImage: `url("/home/murales/${img}")` }}>
			<AnimatePresence>
				{
					info &&
					<motion.div
						initial={{ opacity: 0, x: 0 }}
						animate={{ opacity: 1, x: 0 }}
						exit={{ opacity: 0, x: 0 }}
						transition={{ duration: 0.8 }}
						className='grid content-end justify-items-start w-full
                     bg-gradient-to-t from-black to-transparent bg-opacity-80
                     absolute bottom-0
                     gap-[20px] p-[24px]
                     md:gap-[24px] md:p-[48px] 
                     h-[215px] md:h-[260px] lg:h-[315px]'>
						<h3
							className='font-[Jost] font-semibold text-white
                        text-[20px] leading-[15px] 
                        md:leading-[21px] 
                        lg:text-[26px] lg:leading-[24px]'>
							{title}
						</h3>
						<p
							className='font-Nunito text-white
                        text-[14px] leading-[18px] 
                        md:text-[20px] md:leading-[20px] 
                        lg:text-[24px] lg:leading-[24px]'>
							{description}
						</p>
						<p
							className='font-[Jost] font-semibold
                        text-[18px] text-[#2ABC05]'>
							{type}
						</p>
					</motion.div>
				}
			</AnimatePresence>
			<AnimatePresence>
				{!isMd && !info &&
					<motion.div
						initial={{ opacity: 0, x: 0 }}
						animate={{ opacity: 1, x: 0 }}
						exit={{ opacity: 0, x: 0 }}
						transition={{ duration: 0.8 }}
						className='flex items-center justify-items-center justify-center
                     bg-[rgba(255,255,255,0.3)] rounded-full absolute
                     w-[36px] h-[36px] top-[14px] right-[14px]
                     sm:w-[40px] sm:h-[40px] sm:top-[24px] sm:right-[24px]'>
						<img src='/home/svg/hand.svg' alt='hand' />
					</motion.div>
				}
			</AnimatePresence>
		</div>
	)
}

export default CardMurales
