import React from 'react'
import BtnCotizaNavbar from '../components/buttons/BtnCotizaNavbar'
import { Link } from 'react-router-dom'

const NavBar = () => {
	return (
		<header
			className='!w-full bg-white shadow-md flex items-center fixed top-0 !z-50
        	h-[59px] phone:h-[72px] sm:h-[88px] lg:h-[104px]'>
			<nav
				className='flex items-center justify-between containerW1200'>
				<Link to="/">
					<img
						src='home/svg/newLogo.svg'
						alt='Logo'
						className='cursor-default
						w-[110px] phone:w-[160px] sm:w-[196px] lg:w-[240px]'
					/>
				</Link>
				<BtnCotizaNavbar />
			</nav>
		</header>
	)
}

export default NavBar
