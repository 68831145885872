import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper/modules';
import CardMurales from '../cards/CardMurales';

export default function SwiperMurales() {

    return (
        <>
            <Swiper
                style={{
                    "--swiper-pagination-color": "rgba(43, 43, 43, 1)",
                    "--swiper-pagination-bullet-inactive-color": "rgba(43, 43, 43, 0.5)",
                    "--swiper-pagination-bullet-size": "15px",
                    "--swiper-pagination-bullet-horizontal-gap": "8px",
                }}
                slidesPerView={1}
                breakpoints={{
                    270: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                        centeredSlides: true
                    },
                    1172: {
                        slidesPerView: 1.5,
                        centeredSlides: true
                    },
                    1240: {
                        slidesPerView: 1.6,
                        centeredSlides: true
                    },
                }}
                loop={true}
                centeredSlides={true}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <CardMurales
                        title="Psycodelic disco"
                        description="Este mural con formas en 3D y colores neón sacan la parte divertida de la fiesta."
                        type="Graffiti"
                        img="psycodelic-disco.png"
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <CardMurales
                        title="Mercado InkaMay"
                        description="La esencia andina cobra vida en este mural, donde un hombre rodeado de frutas y verduras refleja la abundancia regional."
                        type="Escenarios"
                        img="mercado-incamay.png"
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <CardMurales
                        title="Inka"
                        description="El Inka cobra vida en este mural, sus manos sostienen un corazón humano, de donde brota una planta floreciente y vibrante."
                        type="Realismo"
                        img="inka.png"
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <CardMurales
                        title="Piuranitos"
                        description="Este mural cobra vida con personajes oníricos y criaturas mágicas, Representando del norte del Perú."
                        type="Personajes"
                        img="piuranitos.png"
                    />
                </SwiperSlide>
            </Swiper>
        </>
    );
}
